<script setup lang="ts">
import ImagesMosaic from '~/components/images/Mosaic.vue'
import RatioContainer from '~/components/shared/ratioContainer.vue'
import LogoIcon from '~/components/ui/LogoIcon/LogoIcon.vue'

import { useInfluencersStore } from '~/stores/influencers'

import type GrooverBucket from '~/helpers/favorites/grooverBucket'
import type Bucket from '~/types/bucket'
import type { StatsV3Influencer } from '~/types/influencer'

type Props = {
  bucket: Bucket | GrooverBucket
  showListContent?: boolean
  shouldShowBookmark?: boolean
  influencerIdsToBookmark?: number[]
  isSelected?: boolean
  navigating?: boolean
  useBiggerLogo?: boolean
}
const {
  bucket,
  showListContent = false,
  shouldShowBookmark = true,
  influencerIdsToBookmark = [],
  isSelected = false,
  navigating = false,
  useBiggerLogo = false,
} = defineProps<Props>()

const { getInfProfilePictureForSlug } = useGetProfilePicture()
const { bucketIsClassic } = useBucketSelection()
const { GET_BY_ID: GET_INFLUENCER_BY_ID } = useInfluencersStore()

const influencerIds = computed(() => {
  if (bucketIsClassic(bucket)) return bucket.influencers
  else return bucket.influencerIds
})
const influencers = computed(() => {
  return influencerIds.value.reduce((accumulator, influencerId) => {
    const influencer = GET_INFLUENCER_BY_ID(influencerId)

    if (influencer) accumulator.push(influencer)

    return accumulator
  }, [] as StatsV3Influencer[])
})
const pictures = computed(() => {
  return influencers.value.map((influencer) => {
    return getInfProfilePictureForSlug(influencer.slug, {
      target: 'profile_picture',
      size: '400_400',
    })
  })
})
const infIsInBucket = computed(() => {
  return influencerIdsToBookmark.every((influencerId) =>
    influencerIds.value.includes(influencerId),
  )
})
const bookmarkIcon = computed(() => {
  return infIsInBucket.value ? 'fas' : 'far'
})
</script>

<template>
  <RatioContainer :x-aspect="1" :y-aspect="1">
    <div
      class="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
      aria-hidden
    >
      <LogoIcon
        v-if="
          !showListContent && bucketIsClassic(bucket) && bucket.is_groover_made
        "
        :class="{
          isSelected,
        }"
        :size-override="useBiggerLogo ? '44px' : undefined"
        aria-hidden
      />
      <ImagesMosaic
        v-else-if="pictures.length > 1"
        :pictures="pictures"
        class="tw-h-full tw-w-full tw-rounded-full"
      />
      <img
        v-else-if="pictures.length"
        :src="pictures[0]"
        class="tw-h-full tw-w-full tw-rounded-full tw-object-cover tw-object-center"
        alt=""
        loading="lazy"
      />
      <div v-else>
        <i class="fas fa-bookmark tw-text-base tw-text-white" />
      </div>
      <div
        v-if="!navigating && shouldShowBookmark"
        class="overlay"
        :class="{ hasCurrentInfluencer: infIsInBucket }"
      >
        <transition mode="out-in" name="scale">
          <i
            v-if="!navigating"
            :key="bookmarkIcon"
            class="fa-bookmark tw-text-white"
            :class="bookmarkIcon"
          />
        </transition>
      </div>
    </div>
  </RatioContainer>
</template>

<style scoped lang="scss">
.overlay {
  @apply tw-absolute tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-5 tw-opacity-0 tw-transition-all tw-duration-300 tw-ease-in-out;
}
</style>
